<template></template>

<script>
export default {
  name: "DashboardCoreFooter",

  data: () => ({
    links: [
      {
        href: "#",
        text: "Creative Tim",
      },
      {
        href: "#",
        text: "About Us",
      },
      {
        href: "#",
        text: "Blog",
      },
      {
        href: "#",
        text: "Licenses",
      },
    ],
  }),
};
</script>

<style lang="sass">
#dashboard-core-footer
  a
    font-size: .825rem
    font-weight: 500
    text-decoration: none
    text-transform: uppercase
</style>
